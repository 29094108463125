// const fs = require('fs');

// const key = fs.readFileSync(__dirname + '/../../certs/CA/localhost/localhost.decrypted.key');
// const cert = fs.readFileSync(__dirname + '/../../certs/CA/localhost/localhost.crt');
const key = undefined;
const cert = undefined;

const options = {
  credentials: {
    key: key,
    cert: cert
  },
  front: {
    // path: process.env.NODE_ENV === 'production'
    //   ? 'http://51.254.99.41'
    //   : 'localhost',
    // path: 'localhost',
    path: '51.254.99.41',
    port: 3000,
  },
  back: {
    // path: process.env.NODE_ENV === 'production'
    //   ? 'http://51.254.99.41'
    //   : 'localhost',
    // path: 'localhost',
    path: '51.254.99.41',
    port: 8000,
    // port: 80,
  }
};

module.exports = options;