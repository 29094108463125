import "./App.css";

import { Fragment, useEffect, useState } from "react";

import { io } from 'socket.io-client';

import PlayerInfos from "./components/PlayerInfos";
import RoomInfos from "./components/RoomInfos";

import { Radio } from "react-loader-spinner";
import GamePanel from "./components/GamePanel";
import RenderIf from "./components/RenderIf";
import options from "./options";

const socket = io(`http://${options.back.path}:${options.back.port}`, { autoConnect: false });

const App = () => {
  const [isFetchingConnexion, setIsFetchingConnexion] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isInRoom, setIsInRoom] = useState(false);
  const [isInGame, setIsInGame] = useState(false);
  const [isInScoreboard, setIsInScoreboard] = useState(false);

  const [error, setError] = useState(undefined);

  const [playerInfos, setPlayerInfos] = useState(undefined);
  const [roomInfos, setRoomInfos] = useState(undefined);

  const [name, setName] = useState('');

  useEffect(() => {
    if (!socket.connected)
      initSocket();
    return (() => { socket.disconnect(); });
  }, []);

  useEffect(() => {
    if (roomInfos?.status?.room === 'ingame')
      setIsInGame(true);
    else
      setIsInGame(false);

    if (roomInfos)
      setPlayerInfos(roomInfos?.players.find(player => player.id === playerInfos?.id));
    else
      setPlayerInfos({ id: sessionStorage.getItem('uniqueId') });
    return (() => { });
  }, [roomInfos]);

  const initSocket = () => {
    socket.on("connect", () => {
      console.log('connect', socket.id);

      const storedUniqueId = sessionStorage.getItem('uniqueId');

      if (storedUniqueId) {
        // Si l'UUID est déjà présent, envoyez-le au serveur pour associer l'ID socket.io
        socket.emit('setUniqueId', storedUniqueId);
        setPlayerInfos({ id: storedUniqueId, });
      } else {
        // Si l'UUID n'est pas présent, demandez au serveur de générer un nouvel UUID
        socket.emit('requestUniqueId');
      }

      setIsConnected(true);
      setIsFetchingConnexion(false);
    });

    socket.on('reconnect', () => {
      // Lors de la reconnexion, renvoyez l'UUID stocké au serveur pour la réassociation
      const storedUniqueId = sessionStorage.getItem('uniqueId');
      if (storedUniqueId)
        socket.emit('setUniqueId', storedUniqueId);
      else
        socket.emit('requestUniqueId');

      setIsConnected(true);
      setIsFetchingConnexion(false);
    });

    // Écoutez la réponse du serveur pour l'UUID généré
    socket.on('generatedUniqueId', (uniqueId) => {
      // Stockez le nouvel UUID dans le sessionStorage
      sessionStorage.setItem('uniqueId', uniqueId);
      console.log('generatedUniqueId', uniqueId);
      setPlayerInfos({ id: uniqueId, });
    });

    // socket.on("initId", (clientId) => {
    //   console.log('initId', clientId);
    //   setPlayerInfos({ id: clientId, });
    // });
    socket.on("disconnect", () => {
      setIsConnected(false);
      setIsInRoom(false);
      setRoomInfos(undefined);
      setPlayerInfos(undefined);
      setIsFetchingConnexion(false);

      setError('Connexion lost with server');
    });
    socket.on("refreshRoomData", (roomData) => {
      console.log('refreshRoomData', roomData);
      setRoomInfos(roomData);
    });
    socket.on("deleteRoom", (id) => {
      console.log('deleteRoom', id);
      setIsInRoom(false);
      setRoomInfos(undefined);
      setIsFetchingConnexion(false);

      setError('Connexion lost with server');
    });
    socket.on("endGame", (roomInfos) => {
      console.log('endGame', roomInfos);
      setRoomInfos(roomInfos);
      setIsInScoreboard(true);
    });
  };

  const connectSocket = () => {
    if (isFetchingConnexion || isConnected)
      return;

    setError(undefined);

    setIsFetchingConnexion(true);

    socket.connect();
  };

  const createRoom = () => {
    if (isFetchingConnexion || !isConnected || !playerInfos)
      return;

    setError(undefined);

    setIsFetchingConnexion(true);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('createRoom', { uuid, name }, (response) => {
      if (response.status !== 'ok') {
        setError(response.data);
        setIsFetchingConnexion(false);
        return;
      }

      console.log('createRoom', response.data);
      setRoomInfos(response.data);
      setIsInRoom(true);
      setIsFetchingConnexion(false);
    });
  };

  const joinRoom = () => {
    if (isFetchingConnexion || !isConnected || !playerInfos)
      return;

    const roomId = prompt('Enter room ID');
    if (!roomId)
      return;

    setError(undefined);

    setIsFetchingConnexion(true);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('joinRoom', { uuid, roomId, name }, (response) => {
      console.log('joinRoom', roomId);
      console.log('joinRoom', response);
      if (response.status !== 'ok') {
        setError(response.data);
        setIsFetchingConnexion(false);
        return;
      }

      setRoomInfos(response.data);
      setIsInRoom(true);
      setIsFetchingConnexion(false);
    });
  };

  const leaveRoom = () => {
    if (isFetchingConnexion || !isConnected || !playerInfos || !roomInfos)
      return;

    setError(undefined);

    setIsFetchingConnexion(true);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('leaveRoom', { uuid, roomId: roomInfos.id }, (response) => {
      console.log('leaveRoom', roomInfos.id);
      if (response.status !== 'ok') {
        setError(response.data);
        return;
      }

      console.log('leaveRoom', response.data);
      setRoomInfos(undefined);
      setIsInRoom(false);
      setIsFetchingConnexion(false);
    });
  };

  const startGame = () => {
    if (isFetchingConnexion || !isConnected || !playerInfos || !roomInfos || isInGame)
      return;

    setError(undefined);

    setIsFetchingConnexion(true);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('startGame', { uuid, roomId: roomInfos.id }, (response) => {
      if (response.status !== 'ok') {
        setError(response.data);
        setIsFetchingConnexion(false);
        return;
      }

      console.log('startGame', response.data);
      setIsInGame(true);
      setIsFetchingConnexion(false);
    });
  };

  const endGame = () => {
    if (isFetchingConnexion || !isConnected || !playerInfos || !roomInfos || !isInGame)
      return;

    setError(undefined);

    setIsFetchingConnexion(true);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('endGame', { uuid, roomId: roomInfos.id }, (response) => {
      if (response.status !== 'ok') {
        setError(response.data);
        return;
      }

      console.log('endGame', response.data);
      setIsInGame(false);
      setIsFetchingConnexion(false);
    });
  };

  return (
    <Fragment>
      <div className="container">
        <div className="header">
          <h1>BridgeQuest Tester</h1>
          <div className="status">
            <p>
              Status:
              <span className={`c ${isConnected ? 'c-green' : 'c-unset'}`}>
                {isConnected ? "Connecté" : "Non connecté"}
              </span>
            </p>
          </div>

          {error && (
            <div className="mt-1">
              <div className="alert alert-danger" role="alert">
                {error}

                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setError(undefined)}
                >
                  X
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="d-flex flex-column flex-nowrap gap-1">
          <RenderIf isTrue={isConnected && !isInRoom}>
            <input
              type='text'
              placeholder='Enter your name'
              className="px-2 py-2"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </RenderIf>
          <div className="buttonsControllers">
            {!isConnected && (
              <button
                className="btn btn-connect"
                disabled={isFetchingConnexion}
                onClick={() => connectSocket()}
              >
                Connect
                {isFetchingConnexion && (
                  <Radio
                    visible={true}
                    colors={["#fff", "#fff", "#fff"]}
                    height="15"
                    width="15"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                  />
                )}
              </button>
            )}

            {isConnected && !isInRoom && (
              <button
                className="btn"
                disabled={isFetchingConnexion}
                onClick={() => createRoom()}
              >
                Create room
                {isFetchingConnexion && (
                  <Radio
                    visible={true}
                    colors={["#fff", "#fff", "#fff"]}
                    height="15"
                    width="15"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                  />
                )}
              </button>
            )}

            {isConnected && !isInRoom && (
              <button
                className="btn"
                disabled={isFetchingConnexion}
                onClick={() => joinRoom()}
              >
                Join room
                {isFetchingConnexion && (
                  <Radio
                    visible={true}
                    colors={["#fff", "#fff", "#fff"]}
                    height="15"
                    width="15"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                  />
                )}
              </button>
            )}

            {isConnected && isInRoom && (
              <button
                className="btn"
                disabled={isFetchingConnexion}
                onClick={() => leaveRoom()}
              >
                Leave room
                {isFetchingConnexion && (
                  <Radio
                    visible={true}
                    colors={["#fff", "#fff", "#fff"]}
                    height="15"
                    width="15"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                  />
                )}
              </button>
            )}

            {isConnected && isInRoom && (!isInGame || isInScoreboard) && roomInfos.owner === playerInfos.id && (
              <button
                className="btn bg-green"
                disabled={isFetchingConnexion}
                onClick={() => startGame()}
              >
                Start game
                {isFetchingConnexion && (
                  <Radio
                    visible={true}
                    colors={["#fff", "#fff", "#fff"]}
                    height="15"
                    width="15"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                  />
                )}
              </button>
            )}

            {isConnected && isInRoom && (isInGame && !isInScoreboard) && roomInfos.owner === playerInfos.id && (
              <button
                className="btn bg-red"
                disabled={true || isFetchingConnexion}
                onClick={() => endGame()}
              >
                End game
                {isFetchingConnexion && (
                  <Radio
                    visible={true}
                    colors={["#fff", "#fff", "#fff"]}
                    height="15"
                    width="15"
                    ariaLabel="radio-loading"
                    wrapperStyle={{}}
                    wrapperClass="radio-wrapper"
                  />
                )}
              </button>
            )}

          </div>
        </div>

        <div className="infos">
          {isConnected && (
            <PlayerInfos data={playerInfos} />
          )}
          {isInRoom && (
            <RoomInfos data={roomInfos} socket={socket} />
          )}
          {isInGame && (
            <GamePanel roomInfos={roomInfos} playerId={playerInfos?.id} socket={socket} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default App;
