import React, { Fragment, useEffect, useState } from 'react';

import RenderIf from './RenderIf';

const RoomInfos = ({ data, socket }) => {
  const [rules, setRules] = useState({});

  const handleSubmit = (newRules) => {
    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('updateRoomRules', { uuid, roomId: data.id, newRules: newRules }, (response) => {
      console.log('updateRoomRules', response);
    });
  };

  const handleAddDummy = () => {
    // {id: “”, name: “”, description: “”, location: {latitude: 0, longitude: 0}}
    let dummy = {
      name: '',
      description: '',
      location: {
        latitude: 0,
        longitude: 0
      }
    };
    dummy['name'] = prompt('Enter dummy name');
    dummy['description'] = prompt('Enter dummy description');
    dummy['location']['latitude'] = prompt('Enter dummy latitude');
    dummy['location']['longitude'] = prompt('Enter dummy longitude');

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('createDummy', { uuid, roomId: data.id, dummy: dummy }, (response) => {
      console.log('createDummy', response);
    });
    // socket.emit('deleteDummy', { uuid, roomId: data.id, dummyId: 'b52wn64ut1f' }, (response) => {
    //   console.log('deleteDummy', response);
    // });
  };

  useEffect(() => {
    setRules(data?.rules);
  }, [data]);

  return (
    <Fragment>
      <div className="bloc">
        <h2>Room Infos</h2>

        <div className="content">
          <div className="elem">
            <p>ID:</p>
            <p className="c-unset">
              {data?.id !== undefined ? data.id : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>OWNER:</p>
            <p className="c-unset">
              {data?.owner !== undefined ? data.owner : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>ROOM STATUS:</p>
            <p className={data?.status?.room === 'pending' ? "c-yellow" : "c-green"}>
              {data?.status !== undefined ? data.status.room : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>GAME STATUS:</p>
            <p className={data?.status?.game === 'pending' ? "c-yellow" : "c-green"}>
              {data?.status !== undefined ? data.status.game : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>TIME_START:</p>
            <p className="c-unset">
              {data?.timeStart !== undefined ? data.timeStart : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>RULES:</p>
            <div className='rules-list'>
              <li className="c-unset">
                {data?.rules === undefined ? '-' : Array.from(Object.entries(data.rules)).map(([name, value], index) => (
                  <ul key={index}>
                    <p>
                      {name}:
                    </p>

                    <RenderIf isTrue={name === 'dummies'}>
                      <div className='dummiesContainer'>
                        <button className="btn" onClick={() => handleAddDummy()}>+ Create</button>
                        <div className="dummiesListContainer">
                          <div className="dummiesList">
                            {Object.values(value)?.map((dummy, index) => (
                              <div key={index} className="dummy">
                                <p>{dummy.name}</p>
                                <p>{dummy.description}</p>
                                <p>{dummy.location.latitude}</p>
                                <p>{dummy.location.longitude}</p>
                                <button className="btn" onClick={() => socket.emit('deleteDummy', { uuid: sessionStorage.getItem('uniqueId'), roomId: data.id, dummyId: dummy.id }, (response) => {
                                  console.log('deleteDummy', response);
                                })}>
                                  - Delete
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </RenderIf>

                    <RenderIf isTrue={name !== 'dummies'}>
                      <input
                        onChange={e => setRules({ ...rules, [name]: e.target.value })}
                        value={rules[name] || '1'}
                        type='number'
                      />
                    </RenderIf>

                  </ul>
                ))}
              </li>
              <button className="btn" onClick={() => handleSubmit(rules)}>Update</button>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>PLAYERS:</p>
            <li>
              {data?.players === undefined ? '-' : data.players.map((player, index) => (
                <ul key={index} className={player.role.type === 'human' ? 'c-green' : 'c-red'}>
                  {player.name} ({player.score})
                </ul>
              ))}
            </li>
          </div>
        </div>

      </div>
    </Fragment>
  );
};

export default RoomInfos;;