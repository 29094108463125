import React, { Fragment } from 'react';
import RenderIf from './RenderIf';

const PlayerInfos = ({ data }) => {
  // console.log(data)
  return (
    <Fragment>
      <div className="bloc">
        <h2>Player Infos</h2>

        <div className="content">
          <div className="elem">
            <p>ID:</p>
            <p className="c-unset">
              {data?.id !== undefined ? data.id : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>NAME:</p>
            <p className="c-unset">
              {data?.name !== undefined ? data.name : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>SCORE:</p>
            <p className="c-unset">
              {data?.score !== undefined ? data.score : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>LOCATION:</p>
            <div className="d-flex flex-column">
              <p className='c-unset'>
                {data?.location?.latitude || '-'}
              </p>
              <p className='c-unset'>
                {data?.location?.latitude || '-'}
              </p>
            </div>
          </div>
        </div>


        <div className="content">
          <div className="elem">
            <p>LOG:</p>
            <p className="c-unset">
              {data?.log !== undefined ? data.log : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>ROLE:</p>
            <p className="c-unset">
              {data?.role?.type !== undefined ? data?.role?.type : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem d-flex flex-column">
            <p>SIGNATURES:</p>
            <div className='rules-list'>
              <li className="c-unset">
                {data?.signatures === undefined ? '-' : data.signatures.map((signature, index) => (
                  <ul key={index}>
                    <div className='dummiesContainer'>
                      <div className="dummiesListContainer">
                        <div className="dummiesList">
                          <div className="dummy">
                            <div className='d-flex flex-row flex-nowrap gap-1 w-100 justify-content-between'>
                              <p>date:</p>
                              <div className='d-flex flex-column'>
                                {signature.date || '-'}
                              </div>
                            </div>
                            <div className='d-flex flex-row flex-nowrap gap-1 w-100 justify-content-between'>
                              <p>type:</p>
                              <div className='d-flex flex-column'>
                                {signature.type || '-'}
                              </div>
                            </div>
                            <div className='d-flex flex-column flex-nowrap gap-1 w-100 justify-content-between'>
                              <p>playerFrom:</p>
                              <div className='d-flex flex-column px-2'>
                                {Array.from(Object.entries(signature.playerFrom)).map(([key, value], index) => {
                                  return (
                                    <Fragment key={index}>
                                      <RenderIf isTrue={key !== 'location'}>
                                        <div className='d-flex flex-row flex-nowrap'>{key}: {value || '-'}</div>
                                      </RenderIf>

                                      <RenderIf isTrue={key === 'location'}>
                                        <div className="d-flex flex-column">
                                          <div className='c-unset'>
                                            lat: {value?.latitude || '-'}
                                          </div>
                                          <div className='c-unset'>
                                            long: {value?.latitude || '-'}
                                          </div>
                                        </div>
                                      </RenderIf>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </div>
                            <div className='d-flex flex-column flex-nowrap gap-1 w-100 justify-content-between'>
                              <p>playerTo:</p>
                              <div className='d-flex flex-column px-2'>
                                {Array.from(Object.entries(signature.playerTo)).map(([key, value], index) => {
                                  return (
                                    <Fragment key={index}>
                                      <RenderIf isTrue={key !== 'location'}>
                                        <div className='d-flex flex-row flex-nowrap'>{key}: {value || '-'}</div>
                                      </RenderIf>

                                      <RenderIf isTrue={key === 'location'}>
                                        <div className="d-flex flex-column">
                                          <div className='c-unset'>
                                            lat: {value?.latitude || '-'}
                                          </div>
                                          <div className='c-unset'>
                                            long: {value?.latitude || '-'}
                                          </div>
                                        </div>
                                      </RenderIf>
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                ))}
              </li>
            </div>
          </div>
        </div>

      </div>
    </Fragment >
  );
};

// const initialSignature = {
//   date: null,
//   type: 'QRCode',
//   playerFrom: {
//     id: 'qwefqwf1fqw',
//     name: 'aasa',
//     role: 'human',
//     score: 100,
//     location: {
//       latitude: 10,
//       longitude: 10,
//     }
//   },
//   playerTo: {
//     id: '',
//     name: '',
//     role: '',
//     score: 0,
//     location: {
//       latitude: 10,
//       longitude: 10,
//     },
//   },
// };

export default PlayerInfos;;