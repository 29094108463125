import React, { Fragment, useEffect, useRef, useState } from 'react';
import RenderIf from './RenderIf';

const GamePanel = ({ roomInfos, playerId, socket }) => {
  // const roomInfos = {
  //   "id": "e5s7b8gad7m",
  //   "status": {
  //     "room": "ingame",
  //     "game": "ongoing"
  //   },
  //   "timeStart": 1689957278651,
  //   "owner": "S2lu_a_0tgmehcuwAAAD",
  //   "rules": {
  //     "dummies": [],
  //     "duration_game": 20,
  //     "duration_deploy": 2,
  //     "human_point_minute": 1,
  //     "human_point_signature": 15,
  //     "human_point_left": 200,
  //     "spirit_number": 1,
  //     "spirit_point_convert": 30,
  //     "spirit_range_interactions": 15,
  //     "spirit_spell_drop_distance": 100
  //   },
  //   "players": [
  //     {
  //       "id": "S2lu_a_0tgmehcuwAAAD",
  //       "name": "",
  //       "description": "",
  //       "location": {
  //         "latitude": 10,
  //         "longitude": 10
  //       },
  //       "logs": [],
  //       "role": {
  //         "type": "spirit",
  //         "human": {
  //           "visible": true
  //         },
  //         "spirit": {
  //           "visible": true,
  //           "conversionsLeft": -1,
  //           "conversionSpellLocation": {
  //             "latitude": 10.000440038562411,
  //             "longitude": 9.999203588118457
  //           }
  //         }
  //       },
  //       "score": 1
  //     },
  //     {
  //       "id": "Qtq_TLwyFMPgZJkxAAAF",
  //       "name": "",
  //       "description": "",
  //       "location": {
  //         "latitude": 10,
  //         "longitude": 10
  //       },
  //       "logs": [],
  //       "role": {
  //         "type": "human",
  //         "human": {
  //           "visible": true
  //         }
  //       },
  //       "score": 3
  //     }
  //   ]
  // };
  const [playerInfos, setPlayerInfos] = useState({});
  const convertOrScan = useRef(null);
  const formRef = useRef(null);

  // console.log(roomInfos)
  useEffect(() => {
    const player = roomInfos?.players?.find(player => player.id === playerId);
    setPlayerInfos(player);
  }, [roomInfos]);

  const handleSubmit = (e) => {
    // console.log('handleSubmit', convertOrScan.current, e);
    e.preventDefault();
    console.log('handleSubmit', e.target[0].value);

    const uuid = sessionStorage.getItem('uniqueId');

    if (convertOrScan.current === 'scan') {
      socket.emit('scan', { uuid, roomId: roomInfos.id, playerToScanId: e.target[0].value }, (response) => {
        console.log('scan', response);
      });
    }
    else if (convertOrScan.current === 'convert') {
      socket.emit('conversionSpell', { uuid, roomId: roomInfos.id, playerToConvertId: e.target[0].value }, (response) => {
        console.log('conversionSpell', response);
      });
    }
  };

  const handleResetSpellLocation = (e) => {
    e.preventDefault();
    if (!playerInfos?.role?.spirit?.conversionSpellLocation)
      return;

    console.log('handleResetSpellLocation', playerInfos?.role?.spirit?.conversionSpellLocation);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('resetConversionSpellLocation', { uuid, roomId: roomInfos.id, conversionSpellLocation: playerInfos.role.spirit.conversionSpellLocation }, (response) => {
      console.log('resetConversionSpellLocation', response);
      if (response.status === 'ok')
        setPlayerInfos({ ...playerInfos, role: { ...playerInfos.role, spirit: { ...playerInfos.role.spirit, conversionSpellLocation: response.data } } });
    });
  };

  const handlePickupSpell = (e) => {
    e.preventDefault();
    if (!playerInfos?.role?.spirit?.conversionSpellLocation)
      return;

    console.log('handlePickupSpell', playerInfos?.role?.spirit?.conversionSpellLocation);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('pickupConversionSpell', { uuid, roomId: roomInfos.id, conversionSpellLocation: playerInfos.role.spirit.conversionSpellLocation }, (response) => {
      console.log('pickupConversionSpell', response);
      if (response.status === 'ok')
        setPlayerInfos({ ...playerInfos, role: { ...playerInfos.role, spirit: response.data } });
    });
  };

  const handleInvisibility = (e) => {
    e.preventDefault();

    console.log('handleInvisibility', playerInfos?.role?.spirit?.visible);

    const uuid = sessionStorage.getItem('uniqueId');

    socket.emit('invisibility', { uuid, roomId: roomInfos.id, activate: playerInfos.role.spirit.visible }, (response) => {
      console.log('invisibility', response);
      if (response.status === 'ok')
        setPlayerInfos({ ...playerInfos, role: { ...playerInfos.role, spirit: { ...playerInfos.role.spirit, visible: response.data } } });
    });
  };

  return (
    <Fragment>
      <div className="bloc">
        <h2>Game Panel</h2>

        <div className="content">
          <div className="elem">
            <p>ROLE:</p>
            <p className={playerInfos?.role?.type === undefined ? 'c-unset' : playerInfos?.role?.type === 'human' ? 'c-green' : 'c-red'}>
              {playerInfos?.role?.type !== undefined ? playerInfos?.role?.type : '-'}
            </p>
          </div>
        </div>

        <div className="content">
          <div className="elem">
            <p>SCAN:</p>
            <form ref={formRef} className="scan" onSubmit={handleSubmit}>
              <select>
                {roomInfos?.players?.map((player, index) => (
                  <RenderIf isTrue={player.id !== playerInfos?.id} key={index} >
                    <RenderIf isTrue={playerInfos?.role?.type === 'human' || player?.role?.type !== 'spirit'}>
                      <option value={player.id}>{player.name}</option>
                    </RenderIf>
                  </RenderIf>
                ))}
              </select>
              <div className='d-flex flex-column flex-nowrap gap-1'>
                <div className='d-flex flex-row flex-nowrap gap-1'>
                  <button type='submit' className='btn flex-fill' onClick={() => {
                    convertOrScan.current = 'scan';
                  }}>
                    Scan
                  </button>
                  <RenderIf isTrue={playerInfos?.role?.type === 'spirit'}>
                    <button type='submit' className='btn flex-fill' onClick={() => {
                      convertOrScan.current = 'convert';
                    }}>
                      Convert
                    </button>
                  </RenderIf>
                </div>
                <RenderIf isTrue={playerInfos?.role?.type === 'spirit' && playerInfos?.role.spirit.conversionSpellLocation}>
                  <button type='button' className='btn' onClick={handleResetSpellLocation}>
                    Reset spell location
                  </button>
                  <button type='button' className='btn' onClick={handlePickupSpell}>
                    Pickup spell
                  </button>
                </RenderIf>
              </div>
            </form>
          </div>
        </div>

        <RenderIf isTrue={playerInfos?.role?.type === 'human'}>
          <div className="content">
            <div className="elem">
              <p>VISIBLE:</p>
              <p className='c-unset'>
                {playerInfos?.role?.human?.visible ? 'true' : 'false'}
              </p>
            </div>
          </div>
        </RenderIf>

        <RenderIf isTrue={playerInfos?.role?.type === 'spirit'}>
          <div className="content">
            <div className="elem">
              <p>CONVERSIONS LEFT:</p>
              <p className='c-unset'>
                {playerInfos?.role?.type !== undefined ? playerInfos?.role?.spirit?.conversionsLeft : '-'}
              </p>
            </div>
          </div>

          <div className="content">
            <div className="elem">
              <p>CONVERSIONS LOCATION:</p>
              <div className="d-flex flex-column">
                <p className='c-unset'>
                  {playerInfos?.role?.spirit?.conversionSpellLocation?.latitude || '-'}
                </p>
                <p className='c-unset'>
                  {playerInfos?.role?.spirit?.conversionSpellLocation?.longitude || '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="elem">
              <p>VISIBLE:</p>
              <div className='d-flex flex-column flex-nowrap gap-1 flex-fill'>
                <p className='c-unset text-align-end'>
                  {playerInfos?.role?.spirit?.visible ? 'true' : 'false'}
                </p>
                <button type='button' className='btn' onClick={handleInvisibility}>
                  {playerInfos?.role?.spirit?.visible ? 'Rendre invisible' : 'Rendre visible'}
                </button>
              </div>
            </div>
          </div>

        </RenderIf>

      </div>
    </Fragment>
  );
};

export default GamePanel;;